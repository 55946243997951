import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, of, switchMap } from 'rxjs';
import { Appconfig } from 'src/app/configurations/appconfig';
import { environment } from 'src/environments/environment';
import { CoreService } from '../core.service';
import { UserService } from '../user/user.service';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends CoreService {
  isHomeInfoRequired: boolean = false;
  isUserProfile: boolean = false;
  private _currentAccId: any;
  private _accountDetails: any;
  private _accountLevelConfigs: any;
  private apiUrl = '1/narratives/create';

  public get currentAccId(): any {
    return this._currentAccId;
  }
  public set currentAccId(value: any) {
    this._currentAccId = value;
  }
  public get accountDetails(): any {
    return this._accountDetails;
  }
  public set accountDetails(value: any) {
    this._accountDetails = value;
  }

  public get accountLevelConfigs(): any {
    return this._accountLevelConfigs;
  }
  public set accountLevelConfigs(value: any) {
    this._accountLevelConfigs = value;
  }

  constructor(httpClient: HttpClient, private userService: UserService, private sanitizer: DomSanitizer) {
    super(httpClient);
  }

  getAccountDetails() {
    return this.get(Appconfig.APIENDPOINT.HOME_MODULES.ACCOUNT).pipe(
      map((respData) => {
        // this.processImageData(respData.body);
        this.accountDetails = respData.body;
        if (this.accountDetails?.account?.logo) {
          this.getSelectedImage(this.accountDetails?.account?.logo)
            .subscribe(url => {
              this.accountDetails.account.signedUrl = url;
            })
        }
        if (this.accountDetails?.account?.banner) {
          this.getSelectedImage(this.accountDetails?.account?.banner)
            .subscribe(url => this.accountDetails.account.signedBannerUrl = url)
        } else {
          this.getAWSSignedURL({
            file_name: 'dashbg.png',
            key: 'assets'
          })
            .subscribe(url => this.accountDetails.account.signedBannerUrl = url)
        }
        this.userService.userDetails = respData.body.user;
        return respData.body;
      })
    );
  }

  getUsersDetails() {
    return this.get(Appconfig.APIENDPOINT.HOME_MODULES.USERS).pipe(
      map((respData) => {
        this.userService.userDetails = respData.body.user;
        return respData.body;
      })
    );
  }

  getActivityLogs(page: number = 1) {
    return this.get(`${Appconfig.APIENDPOINT.ME.ACTIVITY_LOGS}?page=${page}`).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  deleteActivityLogs() {
    return this.delete(
      `${Appconfig.APIENDPOINT.ME.CLEAR_ACTIVITY_LOGS}`
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  getHeatMap(id: any) {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + id + '/masterdata/applicationdata').pipe(map(
      respData => {
        return respData.body;
      }));
  }

  getCompaniesDetails() {
    return this.get(Appconfig.APIENDPOINT.HOME_MODULES.COMPANIES).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  getIndustries() {
    return this.get(Appconfig.APIENDPOINT.HOME_MODULES.INDUSTRIES).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  updateBusinessUnit(payload: any, businessUnitId: any) {
    return this.post(
      Appconfig.APIENDPOINT.HOME_MODULES.COMPANIES + '/' + businessUnitId,
      payload
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  createBusinessUnit(payload: any) {
    return this.post(
      Appconfig.APIENDPOINT.HOME_MODULES.COMPANIES,
      payload
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  deleteBusinessUnit(bu_id: any) {
    return this.delete(
      `${Appconfig.APIENDPOINT.IT_STRATEGIES.ROOT}/${bu_id}`
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  getAccountLevelConfigs(accId: any) {
    if (!accId) {
      return this.getAccountDetails().pipe(
        switchMap((respData1) => {
          return this.get(
            Appconfig.APIENDPOINT.HOME_MODULES.ACC_LEVEL_CONFIG + '/' + respData1?.account?._id
          );
        }),
        map((respData2) => {
          this.accountLevelConfigs = respData2.body;
          return respData2.body;
        })
      );
    } else {
      return this.get(
        Appconfig.APIENDPOINT.HOME_MODULES.ACC_LEVEL_CONFIG + '/' + accId
      ).pipe(
        map((respData) => {
          this.accountLevelConfigs = respData.body;
          return respData.body;
        })
      );
    }
  }

  SaveAccountLevelConfigs(accId: any, data: any) {
    return this.post(
      `${Appconfig.APIENDPOINT.HOME_MODULES.SET_ACC_LEVEL_CONFIG}/${accId}`,
      data
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  processImageData(accountObj: any) {
    accountObj.logo = accountObj.account.logo;
    accountObj.banner = accountObj.account.banner;
    // if (accountObj.account.logo) {
    //   // accountObj.logo = environment.S3_API_URL + accountObj.account._id + '/' + accountObj.hq_company_id + '/bu-assets/' + accountObj.logo;
    //   accountObj.logo = accountObj.account.logo;
    // }
    // if (accountObj.account.banner) {
    //   // accountObj.banner = environment.S3_API_URL + 'assets/' + accountObj.banner;
    //   accountObj.banner = accountObj.account.banner || accountObj.banner;
    // }
  }

  UploadImage(data: any) {

    return this.postMultipartImage(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.UPLOAD_IMAGE,
      data,
      null,
      // true
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  deleteImage(data: any) {

    return this.post(
      Appconfig.APIENDPOINT.HOME_MODULES.IMG_ACCOOUNT + data.acc + Appconfig.APIENDPOINT.HOME_MODULES.IMAGE_GALLERY,
      data,
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  getAccountImages(accId: any) {
    return this.get(
      Appconfig.APIENDPOINT.HOME_MODULES.IMG_ACCOOUNT + accId + Appconfig.APIENDPOINT.HOME_MODULES.IMAGE_GALLERY
    ).pipe(
      map((respData) => {
        this.accountLevelConfigs = respData.body;
        return respData.body;
      })
    );
  }

  getLineIcons(search: string, categories: string[]) {
    if (categories.length > 0 || search != '') {
      return this.post(
        `${Appconfig.APIENDPOINT.HOME_MODULES.LINE_ICONS}`,
        {
          search,
          categories
        }
      ).pipe(
        map((respData) => {
          return respData.body;
        })
      );
    }
    else {
      return of();
    }

  }

  getLineIconCategories() {
    return this.get(
      `${Appconfig.APIENDPOINT.HOME_MODULES.LINE_ICON_CATEGORIES}`
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  getModulesAndFeatures() {
    return this.get(
      Appconfig.APIENDPOINT.DATA.FEATURES_AND_MODULES
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  checkAccountPackageDetails(accID: string) {
    return this.get(
      `${Appconfig.APIENDPOINT.SETTINGS.PACKAGE_DETAILS}${accID}`
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  analyseHeatmapWithChatGPT(strat: string, heatmapType: string, dept_id: string, textFormat: Boolean) {
    let URL = `1/users/v3/${strat}/${heatmapType}/${dept_id}/chatgpt`;
    if (textFormat) {
      URL += `?textFormat=true`
    }
    return this.get(
      URL
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  createNarratives(data: any) {
    return this.post(this.apiUrl, data).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  UpadteNarrative(data: any, _id: any) {
    const url = `1/narratives/update/${_id}`;
    return this.post(url, data).pipe(
      map((res) => {
        return res.body;
      })
    );
  }
  getNarrativesList(businessUnitId: string): Observable<any> {
    // const requestUrl = (environment.API_URL );
    const url = `1/narratives/list/user/${businessUnitId}`;
    // return this.get(url);

    return this.get(url).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  Deletenarrativelist(businessUnitId: string): Observable<any> {
    return this.delete(
      `1/narratives/${businessUnitId}`
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  cloneBU(strat: string, data: any) {
    return this.post(
      `${Appconfig.APIENDPOINT.IT_STRATEGIES.ROOT}/${strat}/${data.ToAccID}/clone/edit`,
      data,
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }


  private accountDetailsSubject = new BehaviorSubject<any>(null);
  getAccountDetails$ = this.accountDetailsSubject.asObservable();

  setAccountDetails(accountDetails: any) {
    this.accountDetailsSubject.next(accountDetails);
  }

  getSelectedImage(url: string): Observable<SafeUrl> {
    if (url) {
      // if (url.indexOf('../../../../../../assets') > -1) {
      //   const updatedUrl = url.replace('../../../../../../', '/');
      //   return of(location.origin + updatedUrl)
      // }


      if (!url) return of(url);
      // Rich icons
      if (url?.indexOf('https://main--inspiring-bonbon-e38161.netlify.app') !== -1) {
        return of(this.sanitizer.bypassSecurityTrustUrl(url));
        // else if is for AWS; uploaded images will return only file url and then we have to get presigned URL
      } else if (url?.indexOf("http") === -1 || url?.indexOf("s3.eu-west-2") !== -1) {
        if (url?.indexOf("s3.eu-west-2") !== -1) {
          url = url.split("?")[0];
          url = url.slice(url.lastIndexOf("/") + 1)
        }
        const company = JSON.parse(localStorage.getItem('Company') || '{}')
        const accountID = this.accountDetails?.account?._id || company.acc;
        return this.getAWSSignedURL({
          file_name: url,
          key: `${accountID}/image-gallery`
        })
      } else {
        // This is for full URL images. i.e. unsplash & line-icons
        return of(url);
      }
    }
    return of('');
  }

  getAWSSignedURL(data: any) {
    return this.post(
      `1/users/v3/getsigned`,
      data,
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  getSafeUrl(url: string): SafeUrl {
    // if (url.indexOf('../../../../../../assets') > -1) {
    //   const updatedUrl = url.replace('../../../../../../', '/');
    //   return this.sanitizer.bypassSecurityTrustUrl(location.origin + updatedUrl)
    // }
    // Rich icons
    if (url?.indexOf('https://main--inspiring-bonbon-e38161.netlify.app') !== -1) {
      return this.sanitizer.bypassSecurityTrustUrl(url);
      // else if is for AWS; uploaded images will return only file url and then we have to get presigned URL
    } else if (url?.indexOf("http") === -1) {
      return this.getAWSSignedURL({ file_name: url, key: `${this.accountDetails?.account?._id}/image-gallery` });
    } else {
      // This is for full URL images. i.e. unsplash & line-icons
      return this.sanitizer.bypassSecurityTrustUrl(url);
    }
  }

}
