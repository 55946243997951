import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Appconfig } from 'src/app/configurations/appconfig';
import { AppService } from 'src/app/providers/appservice/app.service';
import { CanvasService } from 'src/app/providers/canvas/canvas.service';
import { DashboardService } from 'src/app/providers/home/dashboard.service';
import { MessageService } from 'primeng/api';
import { SharedService } from 'src/app/modules/home/data.service';
import { LoaderService } from 'src/app/providers/loader/loader.service';
declare var DiagramEditor: any;

import { dia, ui, shapes } from '@joint/plus';
import { StencilService } from './services/stencil-service';
import { ToolbarService } from './services/toolbar-service';
import { InspectorService } from './services/inspector-service';
import { HaloService } from './services/halo-service';
import { KeyboardService } from './services/keyboard-service';
import RappidService from './services/kitchensink-service';

import { ThemePicker } from './components/theme-picker';
import { sampleGraphs } from './config/sample-graphs';

interface Tab {
  id: number;
  title: string;
  graph: joint.dia.Graph;
  paper: joint.dia.Paper;
  active: boolean;
}

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CanvasComponent implements OnInit {
  @Input()
  canvasType: any;
  private rappid: RappidService;
  tabs: Tab[] = [];
  nextTabId: number = 1;

  constructor(private element: ElementRef) {

  }

  ngOnInit() {

    this.addNewTab();
  }

  addNewTab() {
    const projects = [
      {
        name: 'Project 1',
        rag: 'red',
        id: 'project_1'
      },
      {
        name: 'Project 2',
        rag: 'green',
        id: 'project_2'
      },
    ];
    const applications = [
      {
        name: 'Application 1',
        rag: 'red',
        id: 'application_1'
      },
      {
        name: 'Application 2',
        rag: 'green',
        id: 'application_2'
      },
    ];
    this.rappid = new RappidService(
      this.element.nativeElement,
      new StencilService(projects, applications),
      new ToolbarService(),
      new InspectorService(),
      new HaloService(),
      new KeyboardService()
    );
    this.rappid.startRappid();

    const themePicker = new ThemePicker({ mainView: this.rappid });
    document.body.appendChild(themePicker.render().el);

    this.rappid.graph.fromJSON(JSON.parse(sampleGraphs.emergencyProcedure));
  }

}
