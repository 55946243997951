<div class="modal-header py-2 bg-info1 text-light d-flex align-items-center justify-content-between">
  <div class="d-flex align-items-center">
      <p class="align-center h-fs text-uppercase text-light px-4 ">{{headerText}}</p>
  </div>
  <div class="bg-info1">
      <button (click)="SaveNarrative()" class="btn btn-info2 ms-2 text-light m-0 fs-18 px-3">
          <img src="../../../../../../assets/images/uploads/save.svg" alt="" />
          SAVE
      </button>
      <button class="btn btn-info2 ms-1 text-light m-0 fs-18 px-3" (click)="PublishNarrative()" >
          PUBLISH
      </button>
      <button class="btn btn-info2 ms-1 text-light m-0 fs-18 px-3" *ngIf="headerText=='Edit Narrative' " (click)="OnNarrativeCardClick(narrativeid)">
          LAUNCH
      </button>
      <button type="button" class="btn btn-info2 ms-1 text-light m-0 fs-18 px-3 me-2"
              data-bs-dismiss="modal" (click)="cancelpopup()">
          CANCEL
      </button>
  </div>
</div>



  <div class="modal-body popup-body overflow-auto m-0 p-0" style="height: 800px;">
    
    <div class="container-fluid user-list-unit p-0">
      <div class="row m-0"style="min-height:640px">
        <div class=" fs-15" style="width: 320px;" >
          <li class="heading text-info2 fw-500">BUSINESS UNIT</li>
          <ul class="p-0 bu-box"> 
            <li class="text-capitalize" *ngFor="let company of companiesDetails" 
              [ngClass]="{'active': company?.company?._id === activeCompany?.company?._id}" 
              (click)="setActiveCompany(company)">
              <a href="javascript:void()">{{company?.company?.name}}</a>
            </li>            
          </ul>
          
        </div>

        <div class="example-container1" style="
        max-height: 770px;
        /* overflow-y: auto; */
    ">
          <li class="heading text-info2 fw-500">SELECT SCREENS</li>
          <li class="heading bg-white border-bottom bg-secondary">
            <input
              class="form-check-input me-1"
              type="checkbox"
              value=""
              [(ngModel)]="selectAllChecked"
              (change)="toggleSelectAll()"
              id="flexCheckDefault"
              [disabled]="!activeCompany?.company?.name"
        
            />
            <label class="form-check-label" for="flexCheckDefault">
              Select All
            </label>
          </li>
          <ul class="p-0 example-list sc-box" 
       [ngStyle]="{'pointer-events':activeCompany?'':'none'}">
            <div *ngFor="let section of jsonData">
              <h5 class="ms-2 module-name" >{{ section.name }}</h5>
              <div>
                <li  class="heading bg-white border-bottom align-items-center px-2 py-0 ">
                  
                        <div class="example-box py-2" *ngFor="let item of section.children; let i = index">
                          <div (click)="toggleScreens(item)">
                          <input class="form-check-input me-1 " type="checkbox"  [(ngModel)]="item.checked" id="flexCheckDefault1"  id="item{{i}}"
                             />
                          {{item.name}}
                        </div>
                    </div>
                            
                </li>
              </div>
            </div>
          </ul>
          
        </div>
        
        <div class="example-container2" [ngStyle]="{'pointer-events':activeCompany?'':'none'}">
          <li class="heading text-info2 fw-500" >CREATE CUSTOM NARRATIVE</li>
          <div class="p-3 d-flex justify-content-between">
            <div class="pt-2">
              <p class="fs-18 pt-1">Narrative Name</p>
              <input
                type="text"
                class="rounded-0 border-0 border-bottom fs-20 ps- pt-1"
                style="width:710px"
                placeholder="Enter Narrative Name"
                [(ngModel)]="narrativeName"
                (input)="saveName()"
              />
            </div>
            <div class="add-icon" data-bs-toggle="modal" data-bs-target="#ImageUpload" (click)="closepopup()">
              <img [src]="iconUrl || '../../../../../../../assets/images/uploads/DefaultIcon.png'" class="bd-placeholder-img bank-img flex-shrink-0 me-2" width="80" height="80" role="img" appImageFallback [fallbackSrc]="''"/>
            </div>
          </div>
          <div class="p-3">
           
          </div>
          <div class="p-3">
          <div class="editable-list p-3" style="
          height: 580px;
          max-height: 580px;
          overflow-y: auto;
      " >
      <div style="display: flex;background-color: #62bcc3;border-bottom: 1px solid;" class="text-light heading text-info2 fw-500">
       <div style="width: 500px;padding-left: 0px;">Screen Name</div>
       <div>Business Unit Name</div>
      </div>
      <div class="scroll-container" cdkDropList (cdkDropListDropped)="drop($event)">
        <div *ngFor="let item of screensSelected" cdkDrag>
          <div class="editable" style="display: flex;">
            <div class="drag-handle ps-2" cdkDragHandle>
              <img width="24" height="24" src="../../../../../../assets/images/public/reorder.png" />
            </div>
            <input  type="text" [(ngModel)]="item.screenName" value="item.screenName" style="border: none;min-width: 500px;width: 500px;border-bottom: 1px solid;margin-left:15px"/>
            <div style="justify-content: space-between;display: flex; width:100%;border:none">
            <div style="padding-left: 10px;"> {{item?.companyName}}</div>
           <div>
            <img (click)="deleteItem(item.name)"  src="../../../../../../assets/images/uploads/delete-red.svg" alt="" height="20px" width="20px"  class="me-1 cur-cross" />  
           </div>
          </div>
          </div>
         
        </div>
      </div>
            
        </div>
        </div>
        </div>
        
        
        <div class="fs-15" style="width: 320px;min-height: 680px;max-height: 680px;height: 680px;/* overflow-y: auto !important; */" [ngStyle]="{'pointer-events':activeCompany?'':'none'}">
          <li class="heading text-info2 fw-500">USER LIST</li>
          <li class="heading bg-white border-bottom bg-secondary">
            <input
              class="form-check-input me-1"
              type="checkbox"
              value=""
              [(ngModel)]="selectAllUsersChecked"
              (change)="toggleSelectAllUsers()"
              id="flexCheckDefaultall"
            />
            <label class="form-check-label" for="flexCheckDefaultall">
              Select All
            </label>
            <span class="search-icon float-end">
              <img src="../../../../../../assets/images/uploads/search.svg" />
            </span>
          </li>
          <ul class="p-0" style="min-height: 686px;overflow-y: auto;height: 686px;max-height: 686px;">
            <li
              class="heading bg-white border-bottom d-flex align-items-center"
              *ngFor="let user of users; let i = index"
            >
              <input
                class="form-check-input me-1"
                type="checkbox"
                value=""
                id="user1"
                id="user{{i}}"
                [checked]="narratives.selectedUsers?.includes(user._id) || selectAllUsersChecked "
                (change)="toggleUserSelection(user)"
              />
              <img
              [src]="
                user.avatar || '../../../../../../assets/images/public/default-avatar.png'
              "
              alt=""
              class="rounded-3 mx-2"
              width="50px"
              height="50px"
              appImageFallback [fallbackSrc]="'../../../../../../assets/images/public/default-avatar.png'"
            />
              <label class="form-check-label" for="user{{i}}"> {{user.name}} </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  