import { IObejctive, IProject, ISwotObjective } from "../../shared/loopBack";

export interface ISwotMatrix {

    type?: any;
    start: string;
    archive: boolean;
    loopbackStatusUpdated: boolean;
    cd: any;
    ud?: any;
    __v?: any;
    _id?: any;

    oppurtunities: IOppurtunity[];
    strengths: IStrength[];
    threats: IThreat[];
    weaknesses: IWeakness[];

    // This is for Loop back tech strategy
    needForChange: ILBNeedForChange[];
}

export interface IStrength {
    status?: any;
    strength?: string;
    _id?: any;
    response?: string;
    objectives?: IObejctive[];
    selectedObjectives?: IObejctive[];

    projects?: IProject[];
    selectedProjects?: IProject[];
}

export interface IWeakness {
    status?: any;
    weakness?: string;
    _id?: any
    response?: string;
    objectives?: IObejctive[];
    selectedObjectives?: IObejctive[];

    projects?: IProject[];
    selectedProjects?: IProject[];
}

export interface IThreat {
    status?: any;
    threat?: string;
    _id?: any
    response?: string;
    objectives?: IObejctive[];
    selectedObjectives?: IObejctive[];

    projects?: IProject[];
    selectedProjects?: IProject[];
}

export interface IOppurtunity {
    status?: any;
    oppurtunity?: string;
    _id?: any
    response?: string;
    objectives?: IObejctive[];
    selectedObjectives?: IObejctive[];

    projects?: IProject[];
    selectedProjects?: IProject[];
}

// this is for Loop back tech strategy
export interface ILBNeedForChange {
    status?: any;
    category?: string;
    _id?: any;

    objectives?: IObejctive[];
    selectedObjectives?: IObejctive[];

    projects?: IProject[];
    selectedProjects?: IProject[];
}
