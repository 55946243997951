import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, of } from 'rxjs';
import { Appconfig } from 'src/app/configurations/appconfig';
import { CoreService } from '../../core.service';

@Injectable({
  providedIn: 'root',
})
export class ManagedataService extends CoreService {
  public UploadDataConfigs: any = {
    NEVER_FILLED: {
      background: ['text-dark', 'bg-secondary1'],
      icon: '../../../../../../assets/images/uploads/empty.png',
      altText: 'No data has been uploaded in this section',
    },
    FILLED: {
      background: ['text-white', 'bg-success1'],
      icon: '../../../../../../assets/images/uploads/check.svg',
      altText: 'Data in the section has been updated within the last 30 days',
    },
    OVER_90: {
      background: ['text-white', 'bg-danger2'],
      icon: '../../../../../../assets/images/uploads/alert-white.svg',
      altText: 'Data in this section has not been updated for over 3 months',
    },
    OVER_30: {
      background: ['text-dark', 'bg-warning2'],
      icon: '../../../../../../assets/images/uploads/time.svg',
      altText: 'Data in the section has not been updated for over 1 month',
    },
  };

  public UploadDataItems: any = [
    {
      title: 'Project Data',
      route: 'projectdata',
      backend: 'prgData',
      downloadTemplate: Appconfig.EXCEL_DOWNLOAD_TEMPLATES.PROJECT_DATA,
      icon: '../../../../../../assets/images/uploads/project-data.svg',
    },
    {
      title: 'Application Data',
      route: 'applicationdata',
      backend: 'appData',
      downloadTemplate: Appconfig.EXCEL_DOWNLOAD_TEMPLATES.APP_DATA,
      icon: '../../../../../../assets/images/uploads/application data.svg',
    },
    {
      title: 'Supplier Data',
      route: 'supplierdata',
      backend: 'supplierData',
      downloadTemplate: Appconfig.EXCEL_DOWNLOAD_TEMPLATES.SUPPLIER_DATA,
      icon: '../../../../../../assets/images/uploads/Supplier.svg',
    },
    {
      title: 'HR Data',
      route: 'hrdata',
      backend: 'hrData',
      downloadTemplate: Appconfig.EXCEL_DOWNLOAD_TEMPLATES.HR_DATA,
      icon: '../../../../../../assets/images/uploads/hr-data.svg',
    },
  ];

  public RagCategory: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'Green',
      code: 'green',
    },
    {
      name: 'Red',
      code: 'red',
    },
    {
      name: 'Amber',
      code: 'amber',
    },
    {
      name: 'Grey',
      code: 'grey',
    },
  ];

  public ITB_APPROVED: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'Pending Submission',
      code: 'pending submission',
    },
    {
      name: 'Under Consideration',
      code: 'under consideration',
    },
    {
      name: 'Provisionally Approved',
      code: 'provisionally approved',
    },
    {
      name: 'Approved',
      code: 'approved',
    },
    {
      name: 'Deferred',
      code: 'deferred',
    },
    {
      name: 'Rejected',
      code: 'rejected',
    },
  ];

  public PROJECT_TYPE: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'Business',
      code: 'business',
    },
    {
      name: 'IT',
      code: 'it',
    },
  ];

  public PROJECT_CATEGORY: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'Discretionary',
      code: 'discretionary',
    },
    {
      name: 'Non-Discretionary',
      code: 'non-discretionary',
    },
  ];

  public PROJECT_PRIORITY: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'Must Do',
      code: 'must do',
    },
    {
      name: 'Should Do',
      code: 'should do',
    },
    {
      name: 'Could Do',
      code: 'could do',
    },
    {
      name: 'Would Do',
      code: 'would do',
    },
    {
      name: 'High',
      code: 'high',
    },
    {
      name: 'Medium',
      code: 'medium',
    },
    {
      name: 'Low',
      code: 'low',
    },
  ];

  public PROJECT_STAGES: any = [
    {
      name: 'Select',
      code: 'select',
    },
    { name: 'Planned', code: 'planned' },
    { name: 'Initiation / Kick-off', code: 'initiation / kick-off' },
    { name: 'Requirements Analysis', code: 'requirements analysis' },
    { name: 'Pilot/POC', code: 'pilot/poc' },
    { name: 'Business Case', code: 'business case' },
    { name: 'Design', code: 'design' },
    { name: 'Build & Test', code: 'build & test' },
    { name: 'Transition / Warranty', code: 'transition / warranty' },
    { name: 'Live', code: 'live' },
    { name: 'Complete', code: 'complete' },
    { name: 'Shutdown', code: 'shutdown' },
  ];

  PROJECT_BENEFITS_DROPDOWN: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      code: 'revenue generation',
      name: 'Revenue Generation',
    },
    {
      code: 'revenue protection',
      name: 'Revenue Protection',
    },
    {
      code: 'growth',
      name: 'Growth',
    },
    {
      code: 'competitive advantage',
      name: 'Competitive Advantage',
    },
    {
      code: 'customer delight',
      name: 'Customer Delight',
    },
    {
      code: 'innovation',
      name: 'Innovation',
    },
    {
      code: 'cost reduction',
      name: 'Cost Reduction',
    },
    {
      code: 'risk reduction',
      name: 'Risk reduction',
    },
    {
      code: 'operational efficiency / capacity creation',
      name: 'Operational Efficiency / Capacity Creation',
    },
    {
      code: 'operational excellence',
      name: 'Operational Excellence',
    },
    {
      code: 'automation',
      name: 'Automation',
    },
    {
      code: 'modernisation & resilience',
      name: 'Modernisation & Resilience',
    },
    {
      code: 'regulatory / compliance',
      name: 'Regulatory / Compliance',
    },
    {
      code: 'health & safety',
      name: 'Health & Safety',
    },
    {
      code: 'brand / reputation',
      name: 'Brand / Reputation',
    },
    {
      code: 'employee wellbeing / engagement',
      name: 'Employee Wellbeing / Engagement',
    },
  ];

  public chevronType: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'Colour Chevrons Individually',
      code: 'colour chevrons individually',
    },
    {
      name: 'Inherit BO/Tech Theme Colour',
      code: 'inherit bo/tech theme colour',
    },
    {
      name: 'Inherit ITB Approval Colour',
      code: 'inherit itb approval colour',
    },
  ];

  public chevronHeight: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'Default(1x)',
      code: 'default(1x)',
    },
    {
      name: '2x',
      code: '2x',
    },
    {
      name: '3x',
      code: '3x',
    },
  ];

  public HR_CONTRACT_TYPE: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'Permanent',
      code: 'permanent',
    },
    {
      name: 'Contractor',
      code: 'contractor',
    },
    {
      name: 'Supplier',
      code: 'supplier',
    },
    {
      name: 'Vacancy',
      code: 'vacancy',
    },
  ];

  public SUPPLIER_CONTRACT_TYPE: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'Unique',
      code: 'unique',
    },
    {
      name: 'Shared',
      code: 'shared',
    },
    {
      name: 'Common',
      code: 'common',
    },
  ];

  public SUPPLIER_TIER: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'Tier 1 - Critical',
      code: 'tier 1 - critical',
    },
    {
      name: 'Tier 2 - Normal',
      code: 'tier 2 - normal',
    },
  ];

  public SUPPLIER_CONTRACT_STATUS: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'Draft',
      code: 'draft',
    },
    {
      name: 'Approved',
      code: 'approved',
    },
    {
      name: 'Expired',
      code: 'expired',
    },
  ];

  public SUPPLIER_REGION: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'North Americas',
      code: 'north americas',
    },
    {
      name: 'South & Central Americas',
      code: 'south & central americas',
    },
    {
      name: 'EMEA',
      code: 'emea',
    },
    {
      name: 'APAC',
      code: 'apac',
    },
    {
      name: 'Russia & EE',
      code: 'russia & ee',
    },
    {
      name: 'AUS/NZ',
      code: 'aus/nz',
    },
  ];

  public APP_BUSINESS_CRITICALITY: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'Tier 1 - Critical',
      code: 'tier 1 - critical',
    },
    {
      name: 'Tier 2 - Significant',
      code: 'tier 2 - significant',
    },
    {
      name: 'Tier 3 - Important',
      code: 'tier 3 - important',
    },
    {
      name: 'Tier 4 - Minor',
      code: 'tier 4 - minor',
    },
    {
      name: 'Tier 5 - Inconsequential',
      code: 'tier 5 - inconsequential',
    },
  ];

  public APP_TYPE: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'Bespoke - 3rd Party',
      code: 'bespoke - 3rd party',
    },
    {
      name: 'Bespoke - Inhouse',
      code: 'bespoke - inhouse',
    },
    {
      name: 'SaaS',
      code: 'saas',
    },
    {
      name: 'COTS',
      code: 'cots',
    },
  ];

  public APP_STATUS: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'Planned',
      code: 'planned',
    },
    {
      name: 'Pilot/POC',
      code: 'pilot/poc',
    },
    {
      name: 'Business Case',
      code: 'business case',
    },
    {
      name: 'Design',
      code: 'design',
    },
    {
      name: 'Build & Test',
      code: 'build & test',
    },
    {
      name: 'Transition / Warranty',
      code: 'transition / warranty',
    },
    {
      name: 'Live',
      code: 'live',
    },
    {
      name: 'Retired',
      code: 'retired',
    },
  ];

  public APP_COVERAGE: any = [
    {
      name: 'Select',
      code: 'select',
    },
    {
      name: 'Global',
      code: 'global',
    },
    {
      name: 'One Region',
      code: 'one region',
    },
    {
      name: 'Multiple Regions',
      code: 'multiple regions',
    },
    {
      name: 'One Location',
      code: 'one location',
    },
    {
      name: 'Multiple Locations',
      code: 'multiple locations',
    },
  ];

  private _businessUnitDetails: any;
  public get businessUnitDetails(): any {
    return this._businessUnitDetails;
  }
  public set businessUnitDetails(value: any) {
    this._businessUnitDetails = value;
  }

  private _projectDataDetails: any;
  public get projectDataDetails(): any {
    return this._projectDataDetails;
  }
  public set projectDataDetails(value: any) {
    this._projectDataDetails = value;
  }

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getUploadDataDetails(businessUnitId: any) {
    return this.get(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        businessUnitId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.UPLOAD_DATA
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  getBusinessUnitDetails(businessUnitId: any) {
    return this.get(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.COMPANIES + businessUnitId
    ).pipe(
      map((respData) => {
        this.businessUnitDetails = respData.body;
        return this.businessUnitDetails;
      })
    );
  }

  getProjectData(strategyId: any, isFresh?: any) {
    return this.get(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        strategyId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.PROJECT_DATA
    ).pipe(
      map((respData) => {
        this.projectDataDetails = respData.body;
        return this.projectDataDetails;
      })
    );
  }

  uploadMasterDataTemplate(stratId: any, type: any, formData: any) {
    return this.postFormData(
      this.formUploadDataURL(stratId, type),
      formData
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  deleteManageDataExcelData(stratId: any, type: any) {
    return this.delete(this.formDeleteDataURL(stratId, type)).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  saveExcelUploadDataMapping(postBody: any, stratId: any, uploadId: any) {
    return this.post(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        'v3/' +
        stratId +
        '/masterdata/' +
        uploadId,
      postBody
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  deleteUploadedExcelDataOnCancel(uploaderId: any, stratId: any) {
    return this.delete(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        stratId +
        '/masterdata/' +
        uploaderId
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  formUploadDataURL(stratId: any, type: any) {
    if (type == 'projectdata') {
      return (
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        stratId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.UPLOAD_PROJ_DATA
      );
    } else if (type == 'applicationdata') {
      return (
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        stratId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.UPLOAD_APP_DATA
      );
    } else if (type == 'supplierdata') {
      return (
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        stratId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.UPLOAD_SUPPLIER_DATA
      );
    } else {
      return (
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        stratId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.UPLOAD_HR_DATA
      );
    }
  }

  formDeleteDataURL(stratId: any, type: any) {
    if (type == 'projectdata') {
      return (
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        stratId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.PROJECT_DATA
      );
    } else if (type == 'applicationdata') {
      return (
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        stratId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.APPLICATION_DATA
      );
    } else if (type == 'supplierdata') {
      return (
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        stratId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.SUPPLIER_DATA
      );
    } else {
      return (
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        stratId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.HR_DATA
      );
    }
  }

  getSupplierDataForProject(stratId: any) {
    return this.get(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        stratId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.SUPPLIER_DATA
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  getApplicationDataForProject(stratId: any) {
    return this.get(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        stratId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CRITICAL_APP_DATA
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  getCustomFieldsForProject(accId: any) {
    return this.get(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.ACC_LEVEL_CONFIG +
        accId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT
          .CATEGORIES_UNIQUE_PROJECT_DATA
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  getHRDataForProject(stratId: any) {
    return this.get(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        stratId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.HR_DATA
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  updateForm(stratId: string, data: any){
    // /:strategy_id/v2/update/:module_code/:id
    return this.post(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
      stratId + '/v2/update/projectdata/' + 
        data._id,
      data
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  saveProjectData(
    prjData: any,
    chevron: any,
    strategyId: any,
    isNew?: boolean
  ) {
    if (isNew) {
      let projectPostData: any = this.generateProjectPost(prjData, chevron);
      delete projectPostData['_id'];
      return this.post(
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
          strategyId +
          Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.PROJECT_ADD_NEW_DATA,
        projectPostData
      ).pipe(
        map((respData) => {
          return respData.body;
        })
      );
    } else {
      return this.post(
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
          strategyId +
          Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.PROJECT_DATA,
        this.generateProjectPost(prjData, chevron)
      ).pipe(
        map((respData) => {
          return respData.body;
        })
      );
    }
  }

  deleteProjectData(id: any, strategyId: any) {
    const postBody: any = {
      delete: true,
      _id: id,
    };

    return this.post(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        strategyId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.PROJECT_DATA,
      postBody
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  generateProjectPost(prjData: any, chevron: any) {
    console.log(prjData);
    let postObj = {
      prgID: prjData.uniqId,
      prgName: prjData.projName,
      sponsor: prjData.sponsor,
      dependencies: '',
      desc: prjData.projDesc,
      prgType: prjData.projType,
      // is_BAU_activity: '',
      stage: prjData.projStage,
      rag: prjData.ragStatus,
      edate: prjData.endDate,
      sdate: prjData.startDate,
      invPr: prjData.investPriority,
      invCat: prjData.investCat,
      investment: prjData.financials,
      irr: prjData.irr,
      NPV: prjData.npv,
      payback_date: prjData.payback_date,
      tcapex: 0,
      topex: 0,
      gtotal: 0,
      itbApproved: prjData.ITBApproved,
      pic: prjData.pic,
      benefits_realisations: prjData.benefits,
      decision_matrices: prjData.decisions,
      prgCategory: prjData.prgCategory,
      apps: this.updateProjectPostRecord(prjData.apps),
      suppliers: this.updateProjectPostRecord(prjData.suppliers),
      projects: this.updateProjectPostRecord(prjData.projects),
      resources: this.updateProjectPostRecord(prjData.resources),
      projects_depending_on: this.updateProjectPostRecord(prjData.projects_depending_on),
      projects_dependent_on: this.updateProjectPostRecord(prjData.projects_dependent_on),
      manager: prjData.manager,
      _id: prjData._id,
      chevron: {
        type: chevron.type,
        height: chevron.height,
        color: chevron.color,
        text_color: chevron.text_color,
      },
    };

    return postObj;
  }

  updateProjectPostRecord(data: any) {
    let postDataArr: any = [];
    if (data && data.length) {
      data.forEach((obj: any) => {
        postDataArr.push(obj._id);
      });
    }
    return postDataArr;
  }

  /*HR*/
  saveHRData(postBody: any, strategyId: any, isNew?: boolean) {
    if (isNew) {
      delete postBody['_id'];
      return this.post(
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
          strategyId +
          Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.HR_ADD_NEW_DATA,
        postBody
      ).pipe(
        map((respData) => {
          return respData.body;
        })
      );
    } else {
      return this.post(
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
          strategyId +
          Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.HR_DATA,
        postBody
      ).pipe(
        map((respData) => {
          return respData.body;
        })
      );
    }
  }

  deleteHRData(id: any, strategyId: any) {
    const postBody: any = {
      delete: true,
      _id: id,
    };

    return this.post(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        strategyId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.HR_DATA,
      postBody
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  /*Supplier*/
  getCustomFieldsForSupplier(accId: any) {
    return this.get(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.ACC_LEVEL_CONFIG +
        accId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT
          .CATEGORIES_UNIQUE_SUPPLIER_DATA
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  deleteSupplierData(id: any, strategyId: any) {
    const postBody: any = {
      delete: true,
      _id: id,
    };

    return this.post(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        strategyId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.SUPPLIER_DATA,
      postBody
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  saveSupplierData(supplierData: any, strategyId: any, isNew?: boolean) {
    if (isNew) {
      delete supplierData['_id'];
      return this.post(
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
          strategyId +
          Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.SUPPLIER_ADD_NEW_DATA,
        supplierData
      ).pipe(
        map((respData) => {
          return respData.body;
        })
      );
    } else {
      return this.post(
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
          strategyId +
          Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.SUPPLIER_DATA,
        supplierData
      ).pipe(
        map((respData) => {
          return respData.body;
        })
      );
    }
  }

  /**Application */
  getCustomFieldsForApplications(accId: any) {
    return this.get(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.ACC_LEVEL_CONFIG +
        accId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CATEGORIES_UNIQUE_APP_DATA
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  getApplicationData(stratId: any) {
    return this.get(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        stratId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.APP_DATA
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

  saveApplicationData(applicationData: any, strategyId: any, isNew?: boolean) {
    if (isNew) {
      delete applicationData['_id'];
      return this.post(
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
          strategyId +
          Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.APP_ADD_NEW_DATA,
        applicationData
      ).pipe(
        map((respData) => {
          return respData.body;
        })
      );
    } else {
      return this.post(
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
          strategyId +
          Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.APPLICATION_DATA,
        applicationData
      ).pipe(
        map((respData) => {
          return respData.body;
        })
      );
    }
  }

  deleteApplicationData(id: any, strategyId: any) {
    const postBody: any = {
      delete: true,
      _id: id,
    };
    return this.post(
      Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
        strategyId +
        Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.APPLICATION_DATA,
      postBody
    ).pipe(
      map((respData) => {
        return respData.body;
      })
    );
  }

/*Get Objectives*/
getCriticalProjectData(strategyId: any, isFresh?: any) {
  return this.get(
    Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
      strategyId +
      Appconfig.APIENDPOINT.BUSINESS_STRATEGY_INPUT.CRITICAL_PROJECTS
  ).pipe(
    map((respData) => {
      this.projectDataDetails = respData.body;
      return this.projectDataDetails;
    })
  );
}


getObjectivesData(stratId: any) {
  return this.get(
    Appconfig.APIENDPOINT.INPUT_MANAGE_PROJECT.CORE +
      stratId +
      Appconfig.APIENDPOINT.BUSINESS_STRATEGY_INPUT.OBJECTIVES
  ).pipe(
    map((respData) => {
      return respData.body;
    })
  );
}
/**/

}
