<!-- <div class="page-content right-container-filter">
    <div class="toolbar-container">Toolbar</div>
    <div class="content">
        <div class="stencil-container" id="stencil"></div>
        <div class="paper-container" id="canvas"></div>
        <div class="inspector-container">
        </div>
        <div class="navigator-container"></div>
    </div>
</div> -->

<div class="joint-app joint-theme-modern right-container-filter">
    <div class="app-header">
        <div class="app-title">
            <h1>JointJS+</h1>
        </div>
        <div class="toolbar-container"></div>
    </div>
    <div class="app-body">
        <div class="stencil-container"></div>
        <div class="paper-container"></div>
        <div class="inspector-container"></div>
        <div class="navigator-container"></div>
    </div>
</div>